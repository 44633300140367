<script setup lang="ts">
const { onAppMounted } = useNavigation()

const globalClasses = () => {
  const platformClasses = isDesktop()
    ? "bg-overlay-primary!"
    : "bg-bg-secondary!"
  return `'text-base t-[#{$global-t-default}] text-base overflow-hidden ${platformClasses}`
}

useHead({
  bodyAttrs: {
    class: globalClasses(),
  },
  titleTemplate: (titleChunk) => {
    return `${titleChunk} | Poly: Intelligent Cloud Storage`
  },
})

onMounted(async () => onAppMounted(true))
</script>

<template>
  <native-drag-region />
  <div class="h-full w-full">
    <slot />
  </div>
</template>
